import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CondicionesUsoComponent } from './condiciones-uso/condiciones-uso.component';
import { PoliticasPrivacidadComponent } from './politicas-privacidad/politicas-privacidad.component';


const routes: Routes = [{ path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
{ path: 'servicio-tecnico', loadChildren: () => import('./servicio-tecnico/servicio-tecnico.module').then(m => m.ServicioTecnicoModule) }, 
{ path: 'cotizar-equipo', loadChildren: () => import('./cotizar-equipo/cotizar-equipo.module').then(m => m.CotizarEquipoModule) },
{ path: 'contacto', loadChildren: () => import('./contacto/contacto.module').then(m => m.ContactoModule) },
{ path: 'condiciones-de-uso', component: CondicionesUsoComponent},
{ path: 'politicas-de-privacidad', component: PoliticasPrivacidadComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
