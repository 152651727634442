<div class="condiciones-uso">
    <div class="container">
        <div>
            <div>
                <h2 class="color-kaizen title">TÉRMINOS Y CONDICIONES</h2>
            </div>
        </div>
        <div>
            <div>
                
                <h3 class="reference-concept color-kaizen">1. Introducción</h3>
                <div class="text-content">
                    El presente documento regula los términos y condiciones de acceso y uso de la página web <a routerLink="/" class="link">https://www.toyosaequipos.com.bo/</a> (en adelante “sitio” o “página”), propiedad de TOYOSA EQUIPOS LTDA., con NIT 383151023• (en adelante “la Empresa”). El acceso y/o utilización del sitio, de todos o parte de sus contenidos y/o servicios significa la plena aceptación de las presentes condiciones.
                </div>

                <h3 class="reference-concept color-kaizen">2. Alcance</h3>
                <div class="text-content">
                    Las presentes condiciones aplicarán a la totalidad del sitio, incluyendo los contenidos y los servicios puestos a disposición de los usuarios en y/o a través del sitio, ya sea por la empresa, usuarios, o terceros. No obstante, el acceso y la utilización de ciertos contenidos y/o servicios puede encontrarse sometido a determinadas condiciones específicas que se indicarán en cada contenido o servicio en los cuales apliquen.
                </div>
                <h3 class="reference-concept color-kaizen">3. Modificaciones</h3>
                <div class="text-content">
                    La empresa se reserva la facultad de modificar en cualquier momento las condiciones generales de uso del sitio. Se recomienda que consulte periódicamente los presentes términos de uso del sitio web.
                </div>
                <h3 class="reference-concept color-kaizen">4. Obligaciones del Usuario</h3>
                <div class="text-content">
                    El usuario deberá respetar en todo momento los términos y condiciones aquí establecidos, así como aquellas condiciones específicas que pudiesen aplicar a determinados contenidos y/o servicios. El usuario deberá utilizar el sitio de forma diligente y asumiendo cualquier responsabilidad que pudiera derivarse del incumplimiento de las normas. Así mismo, el usuario no podrá utilizar el sitio para transmitir, almacenar, divulgar promover o distribuir datos o contenidos no autorizados por la empresa, incluyendo aquellos que sean portadores de virus o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o perjudicar el funcionamiento de cualquier programa o equipo informático o de telecomunicaciones.
                </div>
                <h3 class="reference-concept color-kaizen">5. Responsabilidad del sitio</h3>
                <div class="text-content">
                    El usuario conoce y acepta que el sitio no otorga ninguna garantía de cualquier naturaleza, ya sea expresa o implícita, sobre la idoneidad para un fin determinado de los datos, contenidos, información y servicios que se incorporan y ofrecen desde el Sitio. Exceptuando los casos que la Ley imponga expresamente lo contrario, y exclusivamente con la medida y extensión en que lo imponga, el Sitio no garantiza ni asume responsabilidad alguna respecto a los posibles daños y perjuicios causados por el uso y utilización de la información, datos y servicios del Sitio por parte de terceros. En todo caso, La Empresa excluye cualquier responsabilidad por los daños y perjuicios que puedan deberse a la información y/o servicios prestados o suministrados por terceros diferentes de la Empresa. Toda responsabilidad será del tercero ya sea proveedor o colaborador.
                </div>
                <h3 class="reference-concept color-kaizen">6. Propiedad intelectual e industrial</h3>
                <div class="text-content">
                    Todos los contenidos, marcas, logos, dibujos, documentación, programas informáticos o cualquier otro elemento susceptible de protección por la legislación de propiedad intelectual o industrial, que sean accesibles en el sitio corresponden exclusivamente a la empresa o a sus legítimos titulares y quedan expresamente reservados todos los derechos sobre los mismos. Queda expresamente prohibida la creación de enlaces (links) a cualquier elemento integrante de las páginas web del Sitio sin la autorización de la empresa. El sitio no concede ninguna licencia o autorización de uso al usuario sobre sus contenidos, datos o servicios, distinta de la que expresamente se detalle en las presentes condiciones generales de uso del sitio.
                </div>
                <h3 class="reference-concept color-kaizen">7. Protección de Datos</h3>
                <div class="text-content">
                    Por medio de este sitio web se podrían recoger datos de carácter personal necesarios para la gestión y mantenimiento de algunos de nuestros servicios. Dichos datos son incluidos en nuestros ficheros, sin perjuicio de las finalidades que en cada caso se indiquen, dicha información será guardada y gestionada con su debida confidencialidad para impedir el acceso o uso indebido de sus datos, su manipulación, deterioro o pérdida. En cualquier momento puede Ud. ejercer sus derechos de acceso, rectificación o supresión en relación con dichos datos, dirigiendo su solicitud a la dirección del titular TOYOSA EQUIPOS LTDA.
                </div>
                <h3 class="reference-concept color-kaizen">8. Legislación aplicable, jurisdicción competente y notificaciones</h3>
                <div class="text-content">
                    Las presentes condiciones se rigen y se interpretan de acuerdo con las Leyes del Estado Plurinacional de Bolivia. Todas las notificaciones, requerimientos, peticiones y otras comunicaciones que el Usuario desee efectuar a la Empresa titular del Sitio deberán realizarse por escrito y se entenderá que han sido correctamente realizadas cuando hayan sido recibidas por la Empresa.
                </div>
                <h3 class="reference-concept color-kaizen">9. Citas agendadas en taller de servicio técnico</h3>
                <div class="text-content">
                    Los clientes con cita cuentan con un rango de llegada de 15 minutos, que se distribuyen de la siguiente manera: 5 minutos antes del horario marcado y 10 minutos después de este.
                </div>
                <div class="text-content">Si el cliente llega antes de este rango de tiempo, podrá esperar para pasar a la fila de clientes con cita o pasar de inmediato por la fila de clientes sin cita, (lo cual significará una menor prioridad que los clientes con cita).
                </div>
                <div class="text-content">Si llega después de los 10 minutos del horario marcado, deberá pasar por la fila de clientes sin cita.
                </div>
                <div class="text-content">Hacemos esto para asegurar a nuestros clientes el menor tiempo de espera posible, tanto en el momento de recepción del vehículo como de su reparación.
                </div>
                <div class="text-content">Agradecemos su comprensión y puntualidad.
                </div>
            </div>
        </div>
    </div>
</div>
