<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-6 col-md-4 col-xl-4 iconos-sociales">
                <a href="https://www.facebook.com/Toyosa-Equipos-1991924097548373"><img src="../../assets/images/footer/facebook.svg"/></a>
            </div>
            <div class="col-6 col-md-4 col-xl-4 contact">
                <img src="../../assets/images/footer/cc_toyosa_equipos.svg"/>
            </div>
            <div class="col-12 col-md-4 col-xl-4 logo">
                <img src="../../assets/images/header/logo-toyosa-equipos.png"/>
            </div>
        </div>
    </div>
    <div class="copy">
        <div class="container">
            <div class="row">
                <div class="col-6 col-md-2 col-xl-2">
                    <a routerLink="/condiciones-de-uso" class="copy-link">Condiciones de uso</a>
                </div>
                <div class="col-6 col-md-2 col-xl-2">
                    <a routerLink="/politicas-de-privacidad" class="copy-link">Política de privacidad</a>
                </div>
                <div class="col-12 col-md-8 col-xl-8 copy-firma">
                    © 2023 Toyosa Equipos copyright. all rights reserved
                </div>
            </div>
        </div>
    </div>
</div>
