import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-condiciones-uso',
  templateUrl: './condiciones-uso.component.html',
  styleUrls: ['./condiciones-uso.component.css']
})
export class CondicionesUsoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
